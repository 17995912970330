import React, { useState, useEffect } from "react"
import ModalTiny from "react-bootstrap/Modal"

import BranchEnquiryForm from "../forms/branch-enquiry-form"


const BranchEnquiry = (props,{children}) => {

    const [modalAskformOpen,setAskformOpen] = React.useState(false);
    const openAskformModal = () => {
      setAskformOpen(true);
    }
    const closeAskformModal = () => {
      setAskformOpen(false);
    }

    return (
        <>
            <a className={props.class} href="javascript:void(0);" onClick={openAskformModal}>{props.children}</a>
        
            <ModalTiny show={modalAskformOpen} onHide={closeAskformModal} className="book-a-viewing-modal modal-main-form">
                <ModalTiny.Header closeButton className="contact-close-btn">
                    <ModalTiny.Title className="w-100"><h2 className="text-center mb-0">{props.title ? props.title : 'Get in touch'}</h2>
                    <p className="main-content">Please fill in the form below and we will respond to your enquiry as soon as possible. Alternatively, please call us on <a href="" className="phone-link">0800 093 2269</a>.</p>
                    </ModalTiny.Title>
                </ModalTiny.Header>
                <ModalTiny.Body className="event-section people-details-form-modal"><BranchEnquiryForm branch={props.branch}/></ModalTiny.Body>
            </ModalTiny>
        </>
    )
}

export default BranchEnquiry
